import React from 'react'
import { colors } from '../utils/styles'

const fbPageId = '357599134855405'

const App = ({ children, ...other }) => {
  return (
    <div {...other}>
      {children}
      <div id="fb-root"></div>
      <div
        dangerouslySetInnerHTML={{
          __html: `
            <div class="fb-customerchat"
                    attribution=setup_tool
                    page_id="${fbPageId}"
              theme_color="${colors.brand}"
              logged_in_greeting="Buna! Cu ce te putem ajuta?"
              logged_out_greeting="Buna! Cu ce te putem ajuta?">
                  </div>
            `,
        }}
      ></div>
    </div>
  )
}

export default App
